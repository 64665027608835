export enum SummaryIdEnum {
  SALES = 'sales',
  TOTAL_ORDERS = 'total_orders',
  CANCELLED_ORDERS = 'cancelled_orders',
  CONVERSION_RATE = 'conversion_rate',
  VISITORS = 'visitors',
  SHOP_VIEWS = 'shop_views',
  SALE_PER_ORDER = 'sale_per_order',
  REFUND_ORDERS = 'refund_orders',
  REFUND_SALES = 'refund_sales',
  BUYER = 'buyer',
  SALE_PER_BUYER = 'sale_per_buyer',
}

export enum ComponentUnitEnum {
  PRICE = 'price',
  PERCENT = 'percent',
  NORMAL = 'normal',
}

export enum RankingTabEnum {
  SALE = 'SALE',
  SOLD = 'SOLD',
  VIEW = 'VIEW',
  CONVERSION_RATE = 'CONVERSION_RATE',
}

export enum StatisticEnum {
  REVENUES = 'REVENUES',
  SHOPS = 'SHOPS',
  ORDERS = 'ORDERS',
  VIEWS = 'VIEWS',
  CONVERSION_RATE = 'CONVERSION_RATE',
  REVENUE_PER_SHOP = 'REVENUE_PER_SHOP',
  AVERAGE_PER_SHOP = 'AVERAGE_PER_SHOP',
  CANCELLED_ORDERS = 'CANCELLED_ORDERS',
  REFUND_ORDERS = 'REFUND_ORDERS',
}
