<div class="h-full p-4 bg-white rounded-lg">
  <div class="panel-container h-full flex flex-col gap-4 content-start">
    <!-- Header -->
    <div
      class="min-h-[3.75rem] box-border flex gap-2 items-center text-gray-800 text-lg leading-7 font-semibold pb-4 border-b border-gray-100"
    >
      <ng-content select="[header]"></ng-content>
    </div>
    <!-- End header -->

    <ng-content></ng-content>
  </div>
</div>
