<div class="w-full" [ngClass]="styleClass">
  <button
    [disabled]="disabled"
    (click)="time.toggle($event)"
    class="relative w-full"
  >
    <input
      class="w-full h-11"
      type="text"
      pInputText
      readonly
      [value]="dateString"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
    <i
      class="absolute right-4 top-1/2 translate-y-[-50%] ng-dirty ng-invalid"
      [class]="SoctripIcons.CALENDAR"
      [ngClass]="disabled ? 'opacity-50' : 'cursor-pointer'"
    >
    </i>
  </button>
  <p-overlayPanel #time>
    <div class="w-[324px] -mx-4 -mt-4">
      <p-calendar
        styleClass="w-full select-time"
        [inline]="true"
        [(ngModel)]="date"
        (ngModelChange)="onChangeDate()"
        (onSelect)="time.toggle($event)"
        [minDate]="minDate"
      ></p-calendar>

      <div class="w-full flex gap-2 items-center px-5 border-t pt-4 mt-1">
        <div class="flex-1">
          <input
            class="w-full h-10"
            type="number"
            pInputText
            [min]="0"
            [max]="23"
            [maxLength]="2"
            [(ngModel)]="hours"
            (ngModelChange)="onChangeTime()"
          />
        </div>
        <span>:</span>
        <div class="flex-1">
          <input
            class="w-full h-10"
            type="number"
            pInputText
            [min]="0"
            [max]="59"
            [maxLength]="2"
            [(ngModel)]="minutes"
            (ngModelChange)="onChangeTime()"
          />
        </div>
        <span>:</span>
        <div class="flex-1">
          <input
            class="w-full h-10"
            type="number"
            pInputText
            [min]="00"
            [max]="59"
            [maxLength]="2"
            [(ngModel)]="seconds"
            (ngModelChange)="onChangeTime()"
          />
        </div>
      </div>
    </div>
  </p-overlayPanel>
</div>
