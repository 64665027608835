<div
  *ngIf="visible"
  [ngClass]="stylesClass"
  class="bg-white w-full flex flex-col gap-2 items-center justify-center h-60"
>
  <img
    class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center"
    src="assets/imgs/empty-data.svg"
    [alt]="'common.no-data' | translate | sentenceCase"
  />
  <span class="text-gray-400 text-sm">
    {{ "common.no-data" | translate | sentenceCase }}
  </span>
</div>
