import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent {
  @Input() order: OrderDetailDTO;
 orderPrefix = 'section-order-management.';
 constructor(private fileService:FileService){}

 getTotalProduct(quantity: any, price: any) {
  return price * quantity;
}
getImgWebp(id: string) {
  return this.fileService.getImgWebp(id);
}

getCurrencyCode(): string {
  return 'USD';
}
}
