import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Countries } from 'src/app/core/models/classes/countries';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';

@Component({
  selector: 'app-flags-dropdown',
  templateUrl: './flags-dropdown.component.html',
  styleUrls: ['./flags-dropdown.component.scss'],
})
export class FlagsDropdownComponent implements OnInit {
  selectedCountry: any;
  @Input() language: string;
  @Output() languageChange: EventEmitter<any> = new EventEmitter();

  countries = inject(Countries).data;
  translator = inject(CustomTranslateService);

  constructor() {}

  ngOnInit(): void {
    this.language =
      this.language || (this.translator.getCurrentLanguage() as string);
    this.selectedCountry = this.countries.find(
      (c: any) => c.language === this.language
    );
  }

  changeLocale(country: any) {
    const language =
      (country.language as string) || localStorage.getItem('lang') || 'en';
    this.languageChange.emit(language);
  }
}
