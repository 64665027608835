import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { OrderControllerService, OrderDetailDTO, OrderStatusDirectDTO, Reason, StatusControllerService, TabControllerService } from '@soctrip/angular-order-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'src/app/core/utils/toast.util';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {
   @Input() order: OrderDetailDTO;
   readonly SoctripIcons = SoctripIcons;

  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);
  tabService = inject(TabControllerService);
  orderService = inject(OrderControllerService);
  toast = inject(Toast);
  statusService = inject(StatusControllerService);
  translator = inject(CustomTranslateService);

  orderPrefix = 'section-order-management.';
  orderDescription = `${this.orderPrefix}text-description.`;
  actionPrefix = 'section-action.';
  tabCodePrefix = `${this.orderPrefix}tab-code.`;
  requiredPrefix = 'section-required.';
  statusPrefix = 'section-status.';

  isPending: boolean = false;

  tabList: any[] = [];
  // mutableList: any[] = [];
  // shownTabs: number = 4;
  // selectedTabList: any;

  originalTabCode: string;
  currentOrderTabCode: string;
  progressTabList: any[] = [];

  isCancelOrderVisible = false;
  orderCancelReasonList: Reason[] = [];
  selectedReason?: Reason = undefined;
  cancelNote = '';

  constructor(private errorHandler: ErrorHandler) {
    this.errorHandler = new ErrorHandler(this.toast);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleDataChange();
  }

  ngOnInit(): void {
    this.getOrderCancelReasonList();
  }

  handleDataChange() {
    this.getDefaultTabCode();
    this.getTabsData();
    // this.getMutableList();
    // this.getSelectedDropdownTabList();
  }

  /* updateOrderForAllComponents() {
    this.updateOrder.emit();
  } */

  getDefaultTabCode() {
    this.currentOrderTabCode = this.order?.status?.status_property
      ?.tab as string;
    this.originalTabCode = this.order?.status?.status_property?.tab as string;
  }

  //Response is not in the format of: (data, success, error)
  getTabsData() {
    if (!this.order?.id) return;
    this.isPending = true;
    this.tabService.tabsOrdersIdGet(this.order.id!).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          this.tabList = data;
          // // this.getMutableList();
          // // this.toNestedTabList();
          this.getStatusProgress();
        } else this.handleError(error);
      },
      error: (error: any) => this.handleError(error),
      complete: () => (this.isPending = false),
    });
  }

  // getMutableList() {
  //   this.mutableList =
  //     this.tabList.find(
  //       (tab: any) => tab.code === this.order.status?.status_property?.tab
  //     )?.mutable || [];
  // }

  getOrderCancelReasonList() {
    this.isPending = true;
    this.statusService.statusReasonsGet().subscribe({
      next: ({ data, error, success }) => {
        if (success) {
          this.orderCancelReasonList = data as Reason[];
          this.isPending = false;
        } else this.handleError(error);
      },
      error: (error: any) => this.handleError(error),
    });
  }

  // toNestedTabList(tabList: any[] = this.tabList) {
  //   this.getSelectedDropdownTabList();
  //   if (tabList.length <= this.shownTabs) return;
  //   const list = tabList.slice(this.shownTabs - 1, tabList.length);
  //   tabList.splice(this.shownTabs - 1, tabList.length, list);
  // }

  // getSelectedDropdownTabList(tabList: any[] = this.tabList) {
  //   const list = tabList.flat(Infinity);
  //   const tab = list.find((tab: any) => tab.code === this.originalTabCode);
  //   if (list?.indexOf(tab) >= this.shownTabs - 1) this.selectedTabList = tab;
  // }

  // getStatusSelect(event: any, tab: any) {
  //   if (this.isFlowEnd()) return;
  //   if (tab?.length) this.currentOrderTabCode = this.selectedTabList;
  //   this.currentOrderTabCode = tab.code;
  //   this.getStatusProgress();
  // }

  getStatusProgress() {
    this.progressTabList = [];
    for (const tab of this.tabList) {
      this.progressTabList.push(tab);
      if (tab?.code === this.currentOrderTabCode) {
        break;
      }
    }
  }


   getTabIconResponsive(code?: string): string {
    switch (code) {
      case 'WAIT_FOR_SHIPMENT':
        return SoctripIcons.PACKAGE_SEARCH;
      case 'TO_DELIVER':
        return SoctripIcons.TRUCK_01;
      case 'DELIVERED':
        return SoctripIcons.PACKAGE_CHECK;
      case 'COMPLETED':
        return SoctripIcons.CHECK_DONE_01;
      case 'CANCELLED':
        return SoctripIcons.REFRESH_CCW_01;
      case 'REFUND':
        return SoctripIcons.FLIP_BACKWARD;
      default:
        return 'hidden';
    }
  }

  getTabClass(tab: any): string {
    // const active: string = !this.isFlowEnd() ? 'active' : '';
    const active = '';
    // const arrowType: string =
    // tab?.code == this.currentOrderTabCode ||
    // (tab?.length && this.progressTabList.length === this.shownTabs)
    //   ? 'arrow-selected'
    //   : this.progressTabList.includes(tab)
    //   ? 'arrow-progress'
    //   : 'arrow-normal';
    const arrowType: string =
      tab?.code == this.currentOrderTabCode || tab?.length
        ? 'arrow-selected'
        : this.progressTabList.includes(tab)
        ? 'arrow-progress'
        : 'arrow-normal';

    // const tabCode = tab?.code || this.selectedTabList?.code || '';
    const tabCode = tab?.code || '';

    return `${arrowType} ${active} ${tabCode}`;
  }

  // isTabActive(tab: any): boolean {
  //   if (this.isFlowEnd()) return false;
  //   return this.mutableList.includes(tab?.code) ? true : false;
  // }

  isShowTabName(tab: any): boolean {
    if (tab?.code) return tab?.code === this.currentOrderTabCode;
    // return tab?.length && this.progressTabList.length === this.shownTabs;
    return tab?.length;
  }

  isHideIconInSmallScreen(tab: any): boolean {
    return this.isShowTabName(tab);
  }




  openCancelDialog() {
    this.isCancelOrderVisible = true;
  }


  handleError(error: any) {
    this.errorHandler.handle(error);
    this.isPending = false;
    this.isCancelOrderVisible = false;
  }

  // getTabIconResponsive(code?: string): string {
  //   switch (code) {
  //     case 'TO_SHIP':
  //       return 'pi pi-box';
  //     case 'TO_DELIVER':
  //       return 'pi pi-directions';
  //     case 'DELIVERED':
  //       return 'pi pi-check-square';
  //     case 'COMPLETED':
  //       return 'pi pi-check-circle';
  //     case 'CANCELLED':
  //       return 'pi pi-times-circle';
  //     case 'REFUND':
  //       return 'pi pi-replay';
  //     default:
  //       return 'hidden';
  //   }
  // }

  getStatusIcon(code: string) {
    const url = 'assets/icons/order-status';
    switch (code) {
      case 'TO_SHIP':
      case 'WAIT_FOR_SHIPMENT':
      case 'TO_DELIVER':
      case 'DELIVERED':
      case 'COMPLETED':
      case 'CANCELLED':
      case 'REFUND':
        return `${url}/${code.toLowerCase()}.svg`;
      default:
        return 'hidden';
    }
  }

  isFlowEnd() {
    const endCode = ['COMPLETED', 'CANCELLED', 'REFUND'];
    return endCode.includes(this.originalTabCode);
  }
}
