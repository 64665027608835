import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() type = ''
  @Input() label!: string;
  @Input() variant: 'primary' | 'light' | 'info' | 'outline' | 'danger' = 'primary';
  @Input() class = "";
  @Output() onClick = new EventEmitter();
}
