<button
  [disabled]="disabled"
  [type]="type || ''"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-secondary-color': variant === 'info',
    'btn-secondary-gray': variant === 'outline',
    'btn-destructive-primary': variant === 'danger',
    'opacity-50 hover:opacity-50':  disabled,
  }"
  [class]="
    'btn btn-md ' +
    class
  "
  (click)="onClick.emit(label)"
>
  {{ label }}
</button>
