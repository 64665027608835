import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

@Component({
  selector: 'app-delivery-information',
  templateUrl: './delivery-information.component.html',
  styleUrls: ['./delivery-information.component.scss']
})
export class DeliveryInformationComponent {
  @Input() order: OrderDetailDTO;

  readonly SoctripIcons = SoctripIcons;
  orderPrefix = 'section-order-management.';
  textDescription = 'text-description.';

  getAddress(order: OrderDetailDTO) {
    let addressArray: any[] = [];
    if (order?.address) {
      const { address, city, country, district, ward } = order.address as any;
      addressArray = [address, city, country, district, ward];
    }
    return addressArray.filter((prop) => !!prop).join(', ');
  }

}
