import { Component, Input } from '@angular/core';
import { VoucherTypeEnum } from 'src/app/core/enum/voucher.enum';

@Component({
  selector: 'app-custom-preview-voucher',
  templateUrl: './custom-preview-voucher.component.html',
  styleUrls: ['./custom-preview-voucher.component.scss']
})
export class CustomPreviewVoucherComponent {
  @Input() voucherType: VoucherTypeEnum = VoucherTypeEnum.SHOP;
}
