import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  private borderStyle;
  private borderColor;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.borderWidth ||= '1px';
    this.el.nativeElement.style.borderStyle ||= 'dashed';
    this.el.nativeElement.style.borderColor ||= '#D1D5DB';
    this.el.nativeElement.style.borderRadius ||= '8px';
    this.borderStyle = String(this.el.nativeElement.style.borderStyle);
    this.borderColor = String(this.el.nativeElement.style.borderColor);
  }

  @Output() filesDropped = new EventEmitter();
  @Output() filesHovered = new EventEmitter();

  @HostListener('dragover', ['$event'])
  onDragOver($event: any) {
    $event.preventDefault();
    this.filesHovered.emit(true);
    this.el.nativeElement.style.filter = `brightness(${0.8})`;
    this.el.nativeElement.style.borderColor = '#3F83F8';
    this.el.nativeElement.style.borderStyle = 'solid';
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any) {
    $event.preventDefault();
    this.filesHovered.emit(false);
    this.el.nativeElement.style.filter = `brightness(${1})`;
    this.el.nativeElement.style.borderStyle = this.borderStyle;
    this.el.nativeElement.style.borderColor = this.borderColor;
  }

  @HostListener('drop', ['$event'])
  onDrop($event: any) {
    $event.preventDefault();
    this.filesDropped.emit($event.dataTransfer.files);
    this.filesHovered.emit(false);
    this.el.nativeElement.style.filter = `brightness(${1})`;
    this.el.nativeElement.style.borderStyle = this.borderStyle;
    this.el.nativeElement.style.borderColor = this.borderColor;
  }
}
