<div
  class="flex flex-col gap-5 border border-gray-200 shadow-sm bg-white rounded-md w-full px-6 py-5"
>
  <span class="text-lg font-semibold text-gray-900">{{
    orderPrefix + "product-list" | translate | sentenceCase
  }}</span>
  <div *ngIf="order?.shops?.length" class="grid gap-4">
    <div *ngFor="let shop of order.shops" class="grid gap-4 ">
      <ng-container *ngIf="shop?.products?.length">
        <div *ngFor="let product of shop?.products" class="flex gap-4 items-start justify-between">
          <div class="flex flex-row gap-4 items-start">
          <img
            class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center border border-gray-200 rounded-lg"
            [src]="getImgWebp(product?.avatar?.id)"
            onerror="this.src='assets/imgs/default/image.webp'"
          />
          <div class="w-full flex flex-col gap-1">
            <div class="text-sm text-gray-700 font-semibold text-ellipsis">
              {{ product?.name }}
            </div>
            <div
              *ngIf="product?.prices1 && product?.variation1"
              class="flex gap-1 text-xs font-medium"
            >
              <span class="text-gray-500">{{ product.prices1 }}:</span>
              <span class="text-gray-700">{{ product.variation1 }}</span>
            </div>
            <div
              *ngIf="product?.prices2 && product?.variation2"
              class="flex gap-1 text-xs font-medium"
            >
              <span class="text-gray-500">{{ product.prices2 }}:</span>
              <span class="text-gray-700">{{ product.variation2 }}</span>
            </div>
            <div class="flex gap-1 text-xs font-medium">
              <span class="text-gray-500">
                {{ "common.quantity" | translate | sentenceCase }}:
              </span>
              <span class="text-gray-700">
                {{ product?.quantity || "--" }}
              </span>
            </div>
          </div>
        </div>
          <div class="flex items-end min-w-max">
            <span class="flex gap-2 items-center">
              <span
                *ngIf="
                  product?.original_price &&
                  product?.original_price !== product?.current_price
                "
                class="text-sm text-gray-500 line-through"
              >
                {{ product?.original_price | currency: getCurrencyCode() }}
              </span>
              <span class="font-semibold text-gray-700">
                {{ product?.current_price | currency: getCurrencyCode() }}
              </span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
