<ng-container *ngIf="!isLoading && stateView === 'APP'; else elseNoLogin">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #elseNoLogin>
  <div class="bg-white h-screen w-screen flex items-center justify-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<app-toast-message></app-toast-message>

<iframe
  style="display: none"
  id="socialLogin"
  [src]="iframeUrl"
  title="socialLogin"
  (load)="handleOnLoad()"
></iframe>
