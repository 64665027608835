import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-request-status-detail',
  templateUrl: './request-status-detail.component.html',
  styleUrls: ['./request-status-detail.component.scss']
})
export class RequestStatusDetailComponent {
  @Input() order: OrderDetailDTO;

  constructor(private fileService:FileService){

  }
  orderPrefix = 'section-order-management.';
  orderDescription = `${this.orderPrefix}text-description.`;
  statusPrefix = 'section-status.';
  getCreatedDay(status: any) {
    return status?.created_at;
  }

  getImage(item: any) {
    return this.fileService.getImgWebp(item?.id);
  }
}
