<div
  class="flex flex-col gap-5 p-6 border border-gray-200 rounded-lg bg-white w-full"
>
  <span class="text-lg font-semibold text-gray-900">
    {{ orderPrefix + "order-information" | translate | sentenceCase }}</span
  >
  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-4">
      <div class="flex flex-col gap-1 w-[32%]">
        <span class="text-sm font-medium text-gray-500">{{
          orderPrefix + "order-id" | translate | sentenceCase
        }}</span>
        <div class="flex flex-row gap-1">
          <span class="text-sm font-medium text-gray-700 truncate">
            {{ order.shops?.[0]?.code }}</span
          >
          <i
            class="{{
              SoctripIcons.COPY_03
            }} text-xl text-blue-500 cursor-pointer"
            ngxClipboard
            [cbContent]="order.shops?.[0]?.code ?? ''"
            (cbOnSuccess)="onCopySuccess(order.shops?.[0]?.code)"
          ></i>
        </div>
      </div>

      <div class="flex flex-col gap-1 w-[32%]">
        <span class="text-sm font-medium text-gray-500">{{
          "common.status" | translate | sentenceCase
        }}</span>
        <div class="flex items-center">
          <span
            class="order-status text-xs font-medium {{
              order.status?.status_property?.tab
            }}"
            [style.background-color]="
              order.status?.status_property?.color || '#EAECF0'
            "
            [style.color]="
              order.status?.status_property?.font_color || '#344054'
            "
          >
            {{ order.status?.name }}
          </span>
        </div>
      </div>

      <div class="flex flex-col gap-1 w-[32%]">
        <span class="text-sm font-medium text-gray-500">{{
          orderPrefix + "order-date" | translate | sentenceCase
        }}</span>
        <div class="flex flex-row gap-1">
          <span class="text-sm font-medium text-gray-700 truncate">{{
            order.payment?.created_at | date: "dd/MM/yyyy '•' HH:mm"
          }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-sm font-medium text-gray-500">
        {{ "common.note" | translate | sentenceCase }}
      </span>
      <span class="text-sm font-medium text-gray-700">{{
        order.note || "--"
      }}</span>
    </div>
  </div>
</div>
