<ng-container
  *ngIf="order.payment"
  [ngTemplateOutlet]="paymentTemplate"
  [ngTemplateOutletContext]="{ payment: order.payment }"
></ng-container>

<ng-template #paymentTemplate let-payment="payment">
  <div
    class="flex flex-col gap-5 border border-gray-200 shadow-sm bg-white rounded-md w-full px-6 py-5"
  >
    <span class="text-lg font-semibold text-gray-900">{{
      orderPrefix + "payment-information" | translate | sentenceCase
    }}</span>
    <div class="flex flex-col gap-3">
      <div class="flex flex-row justify-between items-center">
        <span class="text-sm font-normal text-gray-500">{{
          orderPrefix + "order-total" | translate | sentenceCase
        }}</span>
        <span class="text-sm font-semibold text-gray-900">{{
          payment?.order_total | currency: getCurrencyCode()
        }}</span>
      </div>

      <div class="flex items-center justify-between gap-2 text-sm">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "payment-method" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ payment?.payment_method?.name || "--" }}
        </span>
      </div>

      <div
          *ngIf="payment?.vouchers?.length"
          class="flex items-center justify-between text-sm min-w-max"
        >
          <span class="text-gray-500">
            {{ orderPrefix + "vouchers" | translate | sentenceCase }}
          </span>
          <span
            *ngFor="let voucher of payment?.vouchers"
            class="flex items-center justify-center gap-1 py-1 pr-[0.75rem] pl-[0.625rem] rounded-2xl bg-orange-dark-50 text-orange-700"
          >
            {{ voucher?.name }}
          </span>
        </div>
        <div
        *ngIf="payment?.discounts || true"
        class="flex items-center justify-between text-sm min-w-max"
      >
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "discounts" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ -payment?.discounts | currency : getCurrencyCode() }}
        </span>
      </div>

      <div
        footer
        class="flex items-center justify-between pt-4 border-t border-gray-100"
      >
        <span class="font-semibold">
          {{ orderPrefix + "total-payment" | translate | sentenceCase }}
        </span>
        <span
          *ngIf="payment?.payment_total"
          class="text-lg leading-7 font-bold text-orange-dark-500"
        >
          {{ payment?.payment_total | currency : getCurrencyCode() }}
        </span>
      </div>

      <!-- <div class="flex flex-row justify-between items-center">
        <span class="text-sm font-normal text-gray-500">{{
          orderPrefix + "taxes" | translate | sentenceCase
        }}</span>
        <span class="text-sm font-semibold text-gray-900">$12345</span>
      </div>

      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row gap-[2px]">
          <i class="{{ SoctripIcons.TAG_01 }} text-orange-600 text-base"></i>
          <span class="text-sm font-normal text-gray-500">SALE172</span>
        </div>
        <span class="text-sm font-semibold text-gray-900">$12345</span>
      </div>

      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row gap-[2px]">
          <i class="{{ SoctripIcons.TAG_01 }} text-orange-600 text-base"></i>
          <span class="text-sm font-normal text-gray-500">FREESHIP39</span>
        </div>
        <span class="text-sm font-semibold text-gray-900">$12345</span>
      </div>
      <div class="flex">
        <span class="w-full border border-gray-100"></span>
      </div>
      <div class="flex flex-row justify-between items-center">
        <span class="text-base font-semibold text-gray-900">{{
          "common.total" | translate | sentenceCase
        }}</span>
        <span class="text-xl font-semibold text-orange-dark-600">$1,459</span>
      </div> -->
    </div>
  </div>
</ng-template>
