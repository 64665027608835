import { Component, Input, inject } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-refund-product',
  templateUrl: './refund-product.component.html',
  styleUrls: ['./refund-product.component.scss'],
})
export class RefundProductComponent {
  @Input() order: OrderDetailDTO;
  @Input() refundDetail: any = [];
  fileService = inject(FileService);
  orderPrefix = 'section-order-management.';

  getImgWebp(id: string) {
    return this.fileService.getImgWebp(id);
  }

  getTotalProduct(quantity: any, price: any) {
    return price * quantity;
  }
}
