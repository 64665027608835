<ng-container
  *ngIf="uploadedImage || imgThumbnail; then uploaded; else upload"
></ng-container>
<ng-template #upload>
  <div
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    *ngIf="!isEditMode"
    class="upload-container border border-gray-300 shadow-sm rounded-lg p-4 flex flex-col items-center gap-3 w-full cursor-pointer"
    [class.shadow]="!uploadedImage"
    (click)="replaceImage()"
  >
    <div
      class="flex p-2 justify-center items-center rounded-[28px] border border-gray-50 bg-gray-100"
    >
      <span class="{{ SoctripIcons.UPLOAD_CLOUD_02 }} text-base"></span>
    </div>
    <div class="flex flex-col gap-1 items-center">
      <span *ngIf="!uploadedImage" class="text-sm font-medium text-gray-700">
        <span class="text-blue-500 mr-1">{{
          uploadImagePrefix + "click-to-upload" | translate | sentenceCase
        }}</span>
        {{ uploadImagePrefix + "or-drag-and-drop" | translate }}
      </span>
      <ng-container *ngIf="svgType; else elseIsSvgType">
        <span class="text-xs text text-gray-500">{{
          uploadImagePrefix + "accepts-svg-png-or-jpg"
            | translate
            | sentenceCase
        }}</span>
      </ng-container>
      <ng-template #elseIsSvgType>
        <span class="text-xs text text-gray-500">{{
          uploadImagePrefix + "accepts-png-or-jpg" | translate | sentenceCase
        }}</span>
      </ng-template>
    </div>
    <ng-container
      *ngIf="svgType; then isSvgType; else notSvgType"
    ></ng-container>
    <ng-template #isSvgType>
      <input
        #fileInput
        type="file"
        accept="image/svg+xml, image/png, image/jpeg"
        (change)="onFileSelected($event)"
        hidden
      />
    </ng-template>
    <ng-template #notSvgType>
      <input
        #fileInput
        type="file"
        accept="image/png, image/jpeg"
        (change)="onFileSelected($event)"
        hidden
      />
    </ng-template>
  </div>
</ng-template>
<ng-template #uploaded>
  <ng-container *ngIf="!isLoading; else elseTemplate">
    <div
      class="flex flex-1 items-center flex-row gap-6"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
    >
      <ng-container *ngIf="!imgThumbnail; else elseTemplate">
        <img
          [src]="uploadedImage"
          (error)="getAvatarDefault()"
          alt="uploadedImage"
          class="w-[150px] h-[150px] object-cover rounded-lg border border-gray-200 shadow-sm"
        />
      </ng-container>
      <ng-template #elseTemplate>
        <img
          [src]="getThumbnailById(imgThumbnail)"
          alt="uploadedImage"
          (error)="getAvatarDefault()"
          class="w-[150px] h-[150px] object-cover rounded-lg border border-gray-200 shadow-sm"
        />
      </ng-template>
      <div
        *ngIf="!isEditMode && (uploadedImage || imgThumbnail)"
        class="image-details flex flex-col gap-6"
      >
        <ng-container *ngIf="!isFetchedImg; else elseFetchedTemplate">
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-gray-700">{{
              imageName ?? "image-upload-voucher.jpg"
            }}</span>
            <span class="text-sm text-gray-500"
              >{{ imageSize ?? 5000 }} KB</span
            >
          </div>
        </ng-container>
        <ng-template #elseFetchedTemplate>
          <div class="grid grid-cols-1 gap-1">
            <p-skeleton class="w-[200px]"></p-skeleton>
            <p-skeleton class="w-[100px]"></p-skeleton>
          </div>
        </ng-template>

        <div class="flex flex-row gap-2">
          <button
            (click)="replaceImage()"
            class="btn btn-sm btn-secondary-gray"
          >
            {{ uploadImagePrefix + "replace" | translate | sentenceCase }}
          </button>
          <button (click)="removeImage()" class="btn btn-sm btn-secondary-gray">
            {{ uploadImagePrefix + "remove" | translate | sentenceCase }}
          </button>
        </div>
      </div>
      <ng-container
        *ngIf="svgType; then isSvgType; else notSvgTpye"
      ></ng-container>
      <ng-template #isSvgType>
        <input
          #fileInput
          type="file"
          accept="image/svg+xml, image/png, image/jpeg"
          (change)="onFileSelected($event)"
          hidden
        />
      </ng-template>
      <ng-template #notSvgTpye>
        <input
          #fileInput
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileSelected($event)"
          hidden
        />
      </ng-template>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div
      class="flex flex-row items-center justify-center gap-1 p-2 w-[150px] h-[150px]"
    >
      <p-progressSpinner ariaLabel="loading" />
    </div>
  </ng-template>
</ng-template>
