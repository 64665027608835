<div class="border border-gray-200 bg-white rounded-md w-full px-6 py-5 flex flex-col gap-5">
  <span class="text-lg text-gray-900 font-semibold"> {{ orderPrefix + "order-status" | translate | sentenceCase }}</span>
  <div class="grid gap-4">
    <div class="arrow-container flex gap-1">
      <span *ngFor="let tab of tabList" class="arrow {{ getTabClass(tab) }}">
        <span class="flex items-center gap-2 max-md:ml-6 font-semibold">
          <i class="{{ getTabIconResponsive(tab?.code) }}"></i>
          <!-- <img
            [src]="getStatusIcon(tab.code)"
            class="status-image inline-block min-w-[1rem] w-5 h-5 object-contain object-cener"
          /> -->
          <span class="{{ isShowTabName(tab) ? '' : 'hidden' }} lg:inline">
            {{ tabCodePrefix + tab?.code | translate | sentenceCase }}
          </span>
        </span>
      </span>
    </div>
  </div>
</div>


