<div
  class="flex flex-col gap-5 border border-gray-200 bg-white rounded-md w-full h-full px-6 py-5"
>
  <span class="text-lg text-gray-900 font-semibold"
    >{{
      orderPrefix + "request-status-details"
        | translate
        | sentenceCase
    }}</span
  >
  <div class="grid gap-4 content-start text-sm overflow-y-auto">
    <div
      *ngFor="let status of order.status_details; let i = index"
      class="status-progress flex gap-2"
    >
      <div class="progress-line">
        <i class="pi pi-circle-fill icon-order" style="font-size: 0.75rem"></i>
      </div>
      <div class="w-full grid gap-2">
        <div class="w-full flex gap-2 justify-between">
          <span
            *ngIf="status?.name"
            class="flex items-center justify-start gap-1"
          >
            <label
              class="px-3 py-1 text-sm rounded-2xl font-medium"
              [style.background-color]="
                status?.status_property?.color || '#EAECF0'
              "
              [style.color]="status?.status_property?.font_color || '#344054'"
            >
              {{ status?.name || "--" }}
            </label>
            <i
              *ngIf="!status.is_public"
              class="pi pi-lock text-gray-600"
              style="font-size: 0.75rem"
              [title]="
                orderDescription + 'this-status-is-hidden-for-the-user'
                  | translate
                  | sentenceCase
              "
            ></i>
          </span>
        </div>
        <div class="text-sm text-gray-700">
          {{ status?.note }}
        </div>
        <div *ngIf="status?.attachments?.length" class="flex gap-2">
          <img
            *ngFor="let attachment of status?.attachments"
            class="inline-block w-[4.5rem] h-[4.5rem] object-cover object-center rounded-md"
            [src]="getImage(attachment)"
            onerror="this.src='assets/imgs/default/image.webp'"
          />
        </div>
        <div *ngIf="status?.message" class="text-sm text-gray-500 font-normal">
          {{ status?.message }}
        </div>
        <div
          *ngIf="getCreatedDay(status)"
          class="text-sm text-gray-500 font-normal"
        >
          <span class="flex gap-1 min-w-max">
            <span>{{ getCreatedDay(status) | date: "dd/MM/yyyy" }}</span>
            <span>•</span>
            <span>{{ getCreatedDay(status) | date: "HH:mm" }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
