import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set(
      "Authorization",
      `Bearer ${localStorage.getItem("accessToken")}`
    );
    const authReq = request.clone({ headers });
    return next.handle(authReq).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.Unauthorized) {

        }
        return throwError(() => error);
      })
    );
  }
}
