<div [class]="styleClass || class">
  <div
    class="w-full h-full bg-gray-50 flex items-center justify-center"
    [ngClass]="imageClass"
    [ngClass]="{ hidden: !imageLoader }"
  >
    <i class="pi pi-spin pi-spinner text-sm"></i>
  </div>
  <img
    [ngClass]="{ hidden: imageLoader, imageClass }"
    [alt]="alt"
    class="w-full h-full object-cover object-center"
    (error)="onImageError()"
    [src]="imgId"
    (load)="onImageLoad()"
  />
</div>
