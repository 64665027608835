export const SERVICE_ENV = {
  STOCK: 'stock',
  CATALOG: 'catalog',
  SHOP: 'shop',
  STORAGE: 'storage',
  STORAGE_STREAMING: 'storage-streaming',
  ORDER: 'order',
  PROMOTION: 'promotion',
  PAYMENT: 'payment',
  SHIPMENT: 'shipment',
  ADVERTISING: 'advertising',
  ANALYTIC: 'shop-analytic',
};
