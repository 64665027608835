<div
  class="flex flex-col gap-5 px-6 py-5 border border-gray-200 rounded-lg bg-white h-full"
>
  <span class="text-lg font-semibold text-gray-900">{{
    orderPrefix + "delivery-information" | translate | sentenceCase
  }}</span>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-4">
      <div class="flex flex-row gap-4">
        <div class="w-1/3">
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-gray-500">{{
              "common.name" | translate | sentenceCase
            }}</span>
            <span class="text-sm font-medium text-gray-700">{{
              order.address?.user_full_name || "--"
            }}</span>
          </div>
        </div>

        <div class="w-1/3">
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-gray-500">{{
              "common.phone" | translate | sentenceCase
            }}</span>
            <div class="flex flex-row gap-[2px] items-center">
              <span class="text-sm font-medium text-gray-700">
                {{'(' + order.address?.user_phone_number?.substring(0,3) + ')'}}

                {{ order.address?.user_phone_number?.substring(3) }}</span
              >
              <a
                *ngIf="order.address?.user_phone_number"
                class="flex items-center text-primary-700 min-w-max"
                href="tel:{{ order.address?.user_phone_number }}"
              >
                <span class="inline">
                  <i class="{{ SoctripIcons.PHONE }} text-blue-500 text-xl"></i>
                </span>
                <span class="hidden md:hidden">
                  {{ textDescription + "call-now" | translate | sentenceCase }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span class="text-sm text-gray-500 font-medium">{{
          "common.address" | translate | sentenceCase
        }}</span>
        <span class="text-sm text-gray-700 font-medium line-clamp-1">{{
          getAddress(order) || "--"
        }}</span>
      </div>
    </div>
  </div>
</div>
