<!-- Usage: use this code to import component

  <app-preview-voucher [voucherType]="voucherTypeEnum">
    <div tag-name></div>
    <div section-left></div>
    <div section-right></div>
    <div body></div>
  </app-preview-voucher>

-->

<div class="preview-voucher-container" [ngClass]="voucherType">
    <!-- Section ticket -->
    <div class="voucher-ticket-container">
      <div class="voucher-ticket">
        <span class="tag-name">
          <ng-content select="[tag-name]"></ng-content>
        </span>
        <div class="section-left">
          <ng-content select="[section-left]"></ng-content>
        </div>
        <div class="section-right">
          <ng-content class="section-right" select="[section-right]">
          </ng-content>
        </div>
      </div>
    </div>
  
    <!-- Section information -->
    <div class="relative overflow-hidden min-h-[25rem]">
      <img
        src="assets/icons/soctrip-logo.svg"
        class="svg inline-block min-w-[15rem] w-60 h-60 object-contain object-center absolute right-0 top-[5rem] translate-x-[40%]"
        [ngClass]="voucherType"
      />
  
      <ng-content></ng-content>
    </div>
  </div>
  