<div class="flex flex-col gap-3">
  <div class="flex gap-2 flex-wrap">
    <div class="flex justify-center items-center">
      <img
        [src]="getImgWebp(refundDetail?.customer?.avatar_thumbnail_url)"
        alt=""
        class="w-5 h-5 rounded-full"
        onerror="this.src='assets/imgs/default/image.webp'"
      />
    </div>
    <span class="font-semibold text-sm text-gray-900"
      >{{ refundDetail?.customer?.first_name }}
      {{ refundDetail?.customer?.last_name }}
      {{ "common.order" | translate }}</span
    >
  </div>

  <div *ngFor="let shop of order?.shops">
    <div *ngFor="let product of shop?.products" class="flex flex-col gap-3">
      <div class="border-b border-gray-200"></div>
      <div class="flex justify-between mb-3">
        <div class="flex gap-4">
          <app-custom-image
            [imgId]="product?.avatar?.id"
            alt="template"
            class="w-16 h-16 rounded-md"
            imageClass="rounded-md"
          ></app-custom-image>
          <div class="flex flex-col gap-1">
            <span class="font-medium text-base text-gray-700 mr-5">{{
              product?.name
            }}</span>
            <div class="flex gap-2 flex-wrap">
              <div class="flex gap-1 flex-wrap">
                <span class="font-normal text-sm text-gray-500"
                  >{{
                    orderPrefix + "variation" | translate | sentenceCase
                  }}:</span
                >
                <span class="font-medium text-sm text-gray-900">
                  {{ product?.variation1 || "--" }}/{{
                    product?.variation2 || "--"
                  }}</span
                >
              </div>
              <div class="flex gap-1">
                <span class="font-normal text-sm text-gray-500"
                  >{{ "common.quantity" | translate | sentenceCase }}:</span
                >
                <span class="font-medium text-sm text-gray-900">{{
                  product?.quantity || "--"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="font-semibold text-base text-gray-900">
            ${{
              getTotalProduct(
                product?.quantity,
                product?.original_price
              )?.toLocaleString()
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
