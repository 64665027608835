import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {

  constructor(private el: ElementRef,private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    const initialValue = this.el.nativeElement.value;
    const sanitizedValue = this.sanitizeValue(initialValue);

    if (initialValue !== sanitizedValue) {
      this.renderer.setProperty(this.el.nativeElement, 'value', sanitizedValue);
    }
  }

  private sanitizeValue(value: string): string {
    return value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  }
}
