import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import { Component, HostListener, OnInit } from '@angular/core';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  user: any;

  constructor(private translator: CustomTranslateService) {}

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  ngOnInit() {
    const lang = this.translator.getCurrentLanguage();
    this.translator.setLanguage(lang);
    registerLocaleData(localeVi, 'vi');
    this.checkSmallScreen();

    try {
      this.user = JSON.parse(localStorage.getItem('auth') || '{}');
    } catch (error) {}
  }
}
