import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import * as moment from 'moment';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTimeComponent),
      multi: true,
    },
  ],
})
export class SelectTimeComponent implements OnInit, ControlValueAccessor {
  readonly SoctripIcons = SoctripIcons;
  @Input() minDate: Date;
  @Input() placeholder: string = 'dd/mm/yyyy hh:mm';
  formControl: FormControl;
  @Input() styleClass: string = '';

  dateString = '';
  date?: Date;
  hours = 0;
  minutes = 0;
  seconds = 0;
  disabled: boolean;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector
        .get(FormGroupDirective)
        .getControl(ngControl);
    }
    this.date = this.formControl.value;
    this.disabled = !this.formControl.enabled;
    if (this.date) {
      const date = new Date(this.date);
      this.dateString = moment(this.date).format('MM/DD/yyyy HH:mm:ss');
      this.hours = date.getHours();
      this.minutes = date.getMinutes();
      this.seconds = date.getSeconds();
    }

    this.formControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.date = undefined;
        this.dateString = '';
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    });

    this.formControl.statusChanges.subscribe((status) => {
      if (status === 'DISABLED') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    });
  }

  onChangeTime() {
    if (this.hours > 23) {
      this.hours = 23;
    }
    if (this.minutes > 59) {
      this.minutes = 59;
    }
    if (this.seconds > 59) {
      this.seconds = 59;
    }
    if (this.hours < 0) {
      this.hours = 0;
    }
    if (this.minutes < 0) {
      this.hours = 0;
    }
    if (this.seconds < 0) {
      this.seconds = 0;
    }
    if (!this.date) {
      if (this.minDate) {
        this.date = new Date(moment(this.minDate).add('days', 1).toDate());
      } else {
        this.date = new Date();
      }
    }
    this.onSetDate();
  }

  onChangeDate() {
    const date = moment(this.date).startOf('day').toDate().getTime();
    const minDate = moment(this.minDate).startOf('day').toDate().getTime();
    if (this.minDate && date === minDate) {
      this.hours = this.minDate.getHours();
      this.minutes = this.minDate.getMinutes();
      this.seconds = this.minDate.getSeconds();
    }
    this.onSetDate();
  }

  onSetDate() {
    if (this.date) {
      const date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate(),
        this.hours,
        this.minutes,
        this.seconds,
      );

      this.dateString = moment(date).format('MM/DD/yyyy HH:mm:ss');
      this.date = new Date(date);
      this.formControl.setValue(this.date);
    }
  }

  writeValue(obj: any): void {
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onChange(value: string) {}

  onTouch() {}
}
