import { Component, Input, inject } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { MessageService } from 'primeng/api';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
})
export class OrderInformationComponent {
  @Input() order: OrderDetailDTO;
  readonly SoctripIcons = SoctripIcons;
  orderPrefix = 'section-order-management.';

  constructor(
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  onCopySuccess(event: any) {
    this.messageService.add({
      severity: 'success',
      detail: this.translator.sentenceCase(
        `${this.orderPrefix}copied-successfully`,
      ),
    });
  }
}
