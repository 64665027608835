import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './shared/layouts/welcome-layout/welcome-layout.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeLayoutComponent,
    pathMatch: 'full',
  },
  {
    path: 'voucher-module/:moduleName',
    children: [
      {
        path: 'voucher-management',
        loadChildren: () =>
          import('./features/module-voucher/voucher-shop.module').then(
            (m) => m.VoucherShopModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'voucher-management',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'voucher-management',
        loadChildren: () =>
          import('./features/voucher-shop/voucher-shop.module').then(
            (m) => m.VoucherShopModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'voucher-management',
        pathMatch: 'full',
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'voucher-management',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
